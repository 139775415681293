import React, { useEffect } from 'react';
import browserHistory from 'utils/history';
import useProfile from 'src/customer/components/iam/Profile/hooks';
import {
  useAppDispatch,
  useAppSelector,
} from 'src/customer/store/configureStore';
import LoadingStatic from 'components/Loading/Static';
import Panel from '../../../Layout/Panel';
import useChain from '../../Chain/hooks';
import { useWallet } from '../../Wallet/WalletProvider';
import actions from '../actions';

const AccountSelect = () => {
  const { profileId } = useProfile();
  const { chains } = useChain();
  const dispatch = useAppDispatch();
  const { address, chain } = useWallet();

  const { isAutoSelectRequest, isAutoSelectFailure, isAutoSelectSuccess } =
    useAppSelector(({ emoney }) => ({
      isAutoSelectRequest: !!emoney?.account.isAutoSelectRequest,
      isAutoSelectFailure: !!emoney?.account.isAutoSelectFailure,
      isAutoSelectSuccess: !!emoney?.account.isAutoSelectSuccess,
    }));
  useEffect(() => {
    const history = {
      ...browserHistory,
      cancelled: false,
    };
    if (profileId && chains?.length) {
      dispatch(
        actions.autoSelect(
          {
            profileId,
            address,
            chain,
          },
          history,
        ),
      );
    }
    return () => {
      history.cancelled = true;
      return dispatch(actions.selectAccountReset());
    };
  }, [profileId, chains]);

  if (
    chains?.length <= 0 ||
    !profileId ||
    isAutoSelectRequest ||
    (!isAutoSelectFailure && !isAutoSelectSuccess)
  )
    return <LoadingStatic dialog="Loading account" />;

  if (isAutoSelectSuccess)
    return <LoadingStatic dialog="Redirecting to account" />;

  return (
    <Panel warning>
      Could not connect with server, please try reloading the page.
    </Panel>
  );
};

export default AccountSelect;
